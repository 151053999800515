import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const WakeUp = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">this neighborhood needs a little shakin' up</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art wake_up"></div>
          <div className="column is-one-quarter lyrics">
            <p>
              Do you feel it? <br/>
              A warming of the ground? <br/>
              Something is happening <br/>
              Vibrations all around
            </p>

            <p>
              &nbsp;&nbsp;What life of misery <br/>
              &nbsp;&nbsp;such a sleep of terrors <br/>
              &nbsp;&nbsp;what new torment coming <br/>
              &nbsp;&nbsp;to realize all our fears?
            </p>

            <p>
              Wake up! <br/>
              New world, new life, <br/>
              wake up!
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;I liked the cold and quiet <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;a perfect place to sleep <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;I’ll get up in a moment <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;is there anything to eat?
            </p>

            <p>
              Greet the dawn, get to work <br/>
              making a change, get it on<br/>
              Crank the heat, by means and steps <br/>
              Orbiting mirrors, let ‘em shine, shine, shine
            </p>

            <p>
              Lower albedo, thicken the air <br/>
              plant the seeds… everywhere<br/>
              Wake the old ones, help ‘em thrive <br/>
              Bring ‘em all out… in the sun
            </p>

            <p>
              Long I lay in endless sleep <br/>
              my time, my love had passed <br/>
              Now what stirs me, brings me warmth <br/>
              is she here again at last?
            </p>

            <p>
              I was drained and long dispersed <br/>
              now buoyed by life anew <br/>
              accelerated bounty blooms <br/>
              does my lover feel this too?
            </p>
          </div>

          <div className="column is-one-quarter lyrics">
            <p>
              Our perfect world is here now <br/>
              just as I forecast <br/>
              Time to start evolving <br/>
              This time make it last
            </p>

            <p>
              &nbsp;&nbsp;Seems we face another round <br/>
              &nbsp;&nbsp;new anguish comes again <br/>
              &nbsp;&nbsp;The beauty and the tragedy <br/>
              &nbsp;&nbsp;I’ll represent the pain
            </p>

            <p>
              Wake up! <br/>
              New world, new life, <br/>
              wake up!
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;Cell walls are expanding <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;arms and legs and tail <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;duty calls to eat and sleep <br/>
              &nbsp;&nbsp;&nbsp;&nbsp;maybe I’ll become a whale!
            </p>

            <p>
              We need more air, water and heat <br/>
              methane and ice… asteroids! <br/>
              Green the house, think tropical lads <br/>
              plants and forests, build it to last
            </p>

            <p>
              Rains are falling, seas to the brim n<br/>
              ew life… stir the pot <br/>
              World now lives, first job done, peek in now and then <br/>
              Wait for the women and men
            </p>

            <p>
              No more alone I live now <br/>
              above my Sky abides
            </p>

            <p>
              I dance and sing in happiness <br/>
              below my Planet sighs
            </p>

            <p>
              A gift of joy and grace <br/>
              with love, embrace&hellip; new life!
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default WakeUp
